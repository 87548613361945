'use strict';

/**
 * Display error messages and highlight form fields with errors.
 * @param {string} parentSelector - the form which contains the fields
 * @param {Object} fieldErrors - the fields with errors
 */
function loadFormErrors(parentSelector, fieldErrors) { // eslint-disable-line
    // Display error messages and highlight form fields with errors.
    $.each(fieldErrors, function (attr) {
        $('*[name=' + attr + ']', parentSelector)
            .addClass('is-invalid')
            .siblings('.invalid-feedback')
            .html(fieldErrors[attr]);
    });
}

/**
 * Clear the form errors.
 * @param {string} parentSelector - the parent form selector.
 */
function clearPreviousErrors(parentSelector) {
    $(parentSelector).find('.form-control.is-invalid').removeClass('is-invalid');
    $('.error-message').hide();
}
/**
 * Activates invalid class to payment information when there is a payment error
 */
function paymentError() {
    var $paymentInfo = $('#holderName, #cardNumber, #expirationMonth, #expirationYear, #securityCode');
    $paymentInfo.addClass('is-invalid');
    $paymentInfo.parent().addClass('is-invalid');
    // clears the CVV value
    $('#securityCode').val('');
}

module.exports = {
    loadFormErrors: loadFormErrors,
    clearPreviousErrors: clearPreviousErrors,
    paymentError: paymentError
};
